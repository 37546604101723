// box bg
import locationBG from "./bg/6.jpg";
import shopBG from "./bg/2.jpg";
import serviceBG from "./bg/7.jpg";
import interierBG from "./bg/3.jpg";

// logo
import logo from "./logo.png";

const bg = {
  locationBG,
  shopBG,
  serviceBG,
  interierBG,
};

export const images = {
  bg,
  logo,
};
