import image1 from "./1-min.jpg";
import image2 from "./2-min.jpg";
import image3 from "./3-min.jpg";
import image4 from "./4-min.jpg";
import image5 from "./5-min.jpg";
import image6 from "./6-min.jpg";
import image7 from "./7-min.jpg";
import image8 from "./8-min.jpg";
import image9 from "./9-min.jpg";

export const interierImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
];
